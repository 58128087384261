  .app {
    padding: 10px;
  }
  
  .profile {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .profile-image {
    width: 150px;
    margin-top: 30px;
  }
  
  .profile-name {
    font-size: 28px;
  }
  
  .navigation {
    text-align: center;
  }
  
  .body {
    max-width: 700px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }